import { createAction } from 'redux-actions';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { Actions } from '../../constants';
import { asyncRequest } from 'communication/async-request';

const checkSignOffPressed = createAction(Actions.CHECK_SIGNOFF_PRESSED);
const checkSignOffConfirmed = createAction<AcceptanceCheckReport>(Actions.CHECK_SIGNOFF_CONFIRMED);
const checkSignOffFailed = createAction(Actions.CHECK_SIGNOFF_ERROR);

export function checkSignOff(signOffCommand: CheckSignOffParameters) : ThunkAction<Promise<void>, {}, {}, AnyAction> {

  return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {

    const { status, signOffComments, hardStopFailure, signOffUrl } = signOffCommand;

    dispatch(checkSignOffPressed());

    var payload = { status, signOffComments, hardStop: hardStopFailure };

    var signOffPromise = new Promise<void>(function(resolve, reject) {
      asyncRequest({
        url: signOffUrl,
        method: 'POST',
        data: payload,
        callback: function(response: jsendResponse) {
          if (response.status != 'success') {
            dispatch(checkSignOffFailed());
            throw 'Problem saving signing off the check.';
          } else {
            dispatch(checkSignOffConfirmed({
              ...response.data
            }));
            resolve();
          }
        }
      });
     });

    return signOffPromise;
  }
}
