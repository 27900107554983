import { createAction } from 'redux-actions';
import { Actions } from 'checkspa/constants';
import { Dispatch } from 'redux';
import { runAutoCheck } from 'checkspa/actions/async/RunAutoCheck';
import { asyncRequest } from 'communication/async-request';

const deleteRowRequested = createAction<DangerousGoodPackage>(Actions.DELETE_ROW_REQUESTED);
const deleteRowSuccess = createAction<any>(Actions.DELETE_ROW_SUCCESS);
const deleteRowFailure = createAction<{ response: jsendResponse, row: DangerousGoodPackage}>(Actions.DELETE_ROW_FAIL);

export function deleteRow(row: DangerousGoodPackage, autoCheckStatus: RunAutoCheckStatus, onSuccess?: () => void): (dispatch: Dispatch<ReduxActions.Action<any>>) => void {

    return (dispatch: Dispatch<ReduxActions.Action<any>>) => {

      dispatch(deleteRowRequested(row));

      asyncRequest({
        url: row.deleteUrl,
        method: 'DELETE',
        callback: function(response: jsendResponse) {
          if (response.status != 'success') {
            alert('There was an error deleting the row');
            dispatch(deleteRowFailure({ response, row }));
          } else {
            var json = response.data;
            dispatch(deleteRowSuccess({ ...json, deletedRow: row }));
            runAutoCheck(autoCheckStatus)(dispatch);
            if (onSuccess) {
              onSuccess();
            }
          }
        }
      });
    }
  }
