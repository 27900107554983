import * as UrlTemplate from 'url-template';

interface Template {
    expand(parameters: any): string;
}

const replaceEmptyIdWithGeneral = (expandTemplate: Template) => {
    var originalExpand = expandTemplate.expand;
    expandTemplate.expand = (parameters: any) => {
        if (!parameters.id) {
            parameters.id = 'general'
        }

        return originalExpand(parameters);
    }

    return expandTemplate;
}

const replaceEmptyIdWithOverview = (expandTemplate: Template) => {
    var originalExpand = expandTemplate.expand;
    expandTemplate.expand = (parameters: any) => {
        if (!parameters.id) {
            parameters.id = 'overview'
        }

        return originalExpand(parameters);
    }

    return expandTemplate;
}

export const homeTemplate = function() { return '/' }
export const dgdLibraryTemplate = function() { return '/dgd-index' }
export const documentationTemplate = replaceEmptyIdWithGeneral(UrlTemplate.parse('/documentation/{id}/{section}'));
export const documentationSetup = "/documentation/setup";
export const packagingTemplate = replaceEmptyIdWithOverview(UrlTemplate.parse('/packaging/{id}/{section}'));
export const verificationTemplate = UrlTemplate.parse('/verify');
export const verifyPropertyTemplate = UrlTemplate.parse('/verify/item/{propertyName}');
export const verifyReferences = '/verify/awb-and-refs';
export const verifyAirports = '/verify/airports';
export const verifyAircraftAndShipment = '/verify/aircraft-and-shipment-type';
export const verifyRowTemplate = UrlTemplate.parse('/verify/row/{id}');
export const addRowTemplate = UrlTemplate.parse('/editor/add');
export const dgdDetails = UrlTemplate.parse('/dgd-index/details/{id}');
export const resourceDownloadUrl = UrlTemplate.parse('/dgd-index/checkresources/{id}/{fileId}');
export const signOffTemplate = UrlTemplate.parse('/signoff');
export const reportUrl = '/report';
export const reportSendByEmailUrl = '/report/send-by-email';
export const xsdgExportUrl = '/report/xsdg-export'
export const xsdgUrl = '/dgd';
export const verifyOrDgdTab = (dgdCanBeVerified: boolean) => {
  if (!dgdCanBeVerified) {
    return xsdgUrl;
  }

  return verificationTemplate.expand({});
}
