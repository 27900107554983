import { createAction } from 'redux-actions';
import { Actions } from 'checkspa/constants';
import { RootState } from 'checkspa/reducers';

export const updateNoteFailed = createAction<UserNote>(Actions.UPDATE_NOTE_ERROR);

export const dgdViewerFocus = createAction<DangerousGoodPackage>(Actions.DGD_VIEWER_FOCUS);
export const dgdViewerFocusOnId = createAction<ViewDgdBlockPayload>(Actions.DGD_VIEWER_FOCUS_ID);
export const dgdViewerCancel = createAction<DangerousGoodPackage>(Actions.DGD_VIEWER_CANCEL);
export const dgdViewerChangePage = createAction<DangerousGoodPackage>(Actions.DGD_VIEWER_CHANGE_PAGE);

export const togglePreviewArea = createAction<string>(Actions.DGD_PREVIEW_AREA_TOGGLE);

export const previewAreaHide = createAction(Actions.DGD_PREVIEW_CLOSE);

export const acceptanceCheckSyncCompleted = createAction<AcSyncResult>(Actions.AC_SYNC_COMPLETED);

/** When we are in the UI and the DGD data has been scanned,  */
export const acceptanceCheckPollingStatusUpdate = createAction<Partial<RootState>>(Actions.AC_POLLING_STATUS_UPDATE);

export { questionOptionPressed, questionAnswerDeselected } from './async/QuestionOptionPressed';
export { documentationComplete } from './async/documentation-complete';
export { documentationSetup } from './async/documentation-setup';
export { updateRow } from './async/UpdateRow';
export { addRows } from './async/AddRows';
export { deleteRow } from './async/DeleteRow';
export { runAutoCheck } from './async/RunAutoCheck';
export { checkSignOff } from './async/CheckSignOff';
export { requestReport } from './async/RequestReport';
export { reorderDgds } from './async/ReorderDgds';
export { validateDgdProperty } from './async/ValidateDgdProperty';
export { validationConfirmed } from './async/ValidationConfirmed';
export { updateNote } from './async/UpdateNote';
export { updateHint } from './async/UpdateHint';
export { displayReference, hideReference } from './async/DgrReference';

export { showRightSidebar, changeActiveTab, changeSidebarWidth, dockSidebar } from './sync/RightSidebar';
export { switchTabsListView } from './sync/SwitchTabsListView';
export { changeRuleVisibility } from './async/UserSettings';

export { createDgdResourceSection, fetchDgdResourceSections, deleteDgdResourceSection, editingDgdResourceSection, editDgdResourceSection } from './async/dgdResourceSections';

export { changeOwnership, closeOwnershipPopup, checkOwnership, releaseDgdAndGoToLibrary } from './async/OwnershipChange';
