import { createAction } from 'redux-actions';
import { Actions } from '../../constants';
import { Dispatch } from 'redux';
import { asyncRequest } from 'communication/async-request';
import { nullSmartVerificationProperties } from 'library/dgd/components/Editable/cleanDangerousGoodPackage';

const validateDgdRequested = createAction<{ rows: DangerousGoodPackage[] }>(Actions.VALIDATE_DGD_REQUEST);
const validateDgdConfirmed = createAction(Actions.VALIDATE_DGD_CONFIRMED);
const validateDgdError = createAction<jsendResponse>(Actions.VALIDATE_DGD_ERROR);

export function validationConfirmed(confirmVerifiedUrl: string, unverifiedProperties: ValidateDgdProperty[], rowsToSave: DangerousGoodPackage[]): (dispatch: Dispatch<ReduxActions.Action<any>>) => void {

  return (dispatch: Dispatch<ReduxActions.Action<any>>) => {

    dispatch(validateDgdRequested({ rows: rowsToSave }));

    let data = {
      properties: unverifiedProperties,
      rows: rowsToSave.map(row => {
        return nullSmartVerificationProperties(row)
      })
    }

    asyncRequest({
      url: confirmVerifiedUrl,
      method: 'POST',
      data: data,
      callback: function(response: jsendResponse) {
        if (response.status == 'success') {
          dispatch(validateDgdConfirmed(response.data));
        } else {
          dispatch(validateDgdError(response));
        }
      }
    });
  }
}
