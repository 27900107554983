import * as Loadable from 'react-loadable';
import * as React from 'react';
import { Refresh } from 'library/icons';

export function LoadingComponent(props: Loadable.LoadingComponentProps) {

  return (
    <div className="react-loading spin-graphic">
      <div className="react-loading__inner">
        { renderLoadingStatus(props) }
      </div>
    </div>
  );
}

function renderLoadingStatus(props: Loadable.LoadingComponentProps) {

  if (props.error) {
    console.error(props.error)
    return <p>A problem occurred loading this area. <button onClick={ props.retry } className="btn btn-primary">Retry</button></p>;
  } else if (props.pastDelay) {
    return <>
      <Refresh width={30} height={30} />
      <p>Loading...</p>
    </>;
  } else {
    return null;
  }
}


export function LoadingMessage() {
  return <LoadingComponent pastDelay={ true } error={null} isLoading={true} retry={ () => {} } timedOut={ false } />
}
