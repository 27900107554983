import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RootState } from 'checkspa/reducers';
import * as CheckActions from 'checkspa/actions/check';
import { DgdViewer } from 'checkspa/ui/components';

export class ReferenceAreaComponent extends React.Component<ReferenceArea.Props, ReferenceArea.State> {

  constructor(props: any, context?: any) {
    super(props, context);

    this.state = {
      visible: true,
      viewingKey: 0
    }
    this.toggleVisibility = this.toggleVisibility.bind(this);
  }

  render() {
    const { rootState, actions } = this.props;

    return <DgdViewer
        dangerousGoods={ rootState.dgdData.dangerousGoods }
        dgdViewerStatus={ rootState.dgdViewerStatus }
        pdfDownloadUrl={ rootState.dgdData.pdfDownloadUrl }
        dgdImages={ rootState.dgdData.dgdImages }
        actions={ actions } />;
  }

  toggleVisibility() {
    this.setState({
      visible: !this.state.visible
    });
  }

  showArea(key: number) {
    return () => {
      this.setState({
        viewingKey: key,
        visible: true
      })
    };
  }
}

declare namespace ReferenceArea {
  export interface Props {
    rootState?: RootState;
    actions?: typeof CheckActions;
  }

  export interface State {
    visible?: boolean;
    viewingKey: number;
  }
}

function mapStateToProps(state: RootState) {
  return {
    rootState: state
  } as ReferenceArea.Props;
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(CheckActions as any, dispatch)
  };
}

export const ReferenceArea = connect(mapStateToProps, mapDispatchToProps)(ReferenceAreaComponent);
