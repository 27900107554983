import { SectionStatus } from 'checkspa/constants/index';

export const calculateDgMenuItemStatus = (sectionStatuses: SectionTab[]) : DgdMenuItemStatus => {
  const hasAutomatedFailures = sectionStatuses.some(w => w.automatedFailures > 0);
  const hasFailed = sectionStatuses.find(f => f.status == SectionStatus.FAILED);
  const hasInconclusive = sectionStatuses.find(f => f.status == null);

  return {
    hasFailures: hasAutomatedFailures,
    status: hasInconclusive ? null : hasFailed ? SectionStatus.FAILED : SectionStatus.PASSED,
    saving: sectionStatuses.some(a => a.saving === true)
  } as DgdMenuItemStatus;
}
