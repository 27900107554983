import { handleActions } from 'redux-actions';
import * as Actions from '../constants/actions';
import { setHintSaving } from './userhints/set-hint-saving';

const initialState: UserHints = [];

export default handleActions<UserHints, UserHint>({

  [Actions.SET_HINT_SAVING]: setHintSaving,
  
  [Actions.SET_HINT_CONFIRMED]: (state, action) => {
    const { rowId, hintIdentifier, value } = action.payload;

    return state.map(hint => {
      if (hint.hintIdentifier == hintIdentifier && hint.rowId == rowId) { 
        return {...hint,
          oldValue: null,
          isSaving: false
        };
      }
      return hint;
    });
  },
  
  [Actions.SET_HINT_ERROR]: (state, action) => {
    const { rowId, hintIdentifier, value } = action.payload;
 
    return state.map(input => {
      if (input.rowId == rowId && input.hintIdentifier == hintIdentifier) {
        return {...input,
          value: input.oldValue,
          oldValue: null,
          isSaving: false
        };
      }

      return input;
    });
  }
}, initialState);
