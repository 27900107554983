import { createAction } from 'redux-actions';
import { Actions } from 'checkspa/constants';
import { Dispatch } from 'redux';
import { runAutoCheck } from 'checkspa/actions/async/RunAutoCheck';
import { asyncRequest } from 'communication/async-request';

const reorderRequested = createAction(Actions.SAVE_REORDER_ROWS_REQUEST);
const reorderSuccess = createAction<DangerousGoodPackage[]>(Actions.SAVE_REORDER_ROWS_SUCCESS);
const reorderFailure = createAction<any>(Actions.SAVE_REORDER_ROWS_FAIL);

export function reorderDgds(rows: DangerousGoodPackage[], reorderRowsUrl: string, autoCheckStatus: RunAutoCheckStatus): (dispatch: Dispatch<ReduxActions.Action<any>>) => void {

    return (dispatch: Dispatch<ReduxActions.Action<any>>) => {

      dispatch(reorderRequested());

      var body = rows.map(row => {
        return {
          rowId: row.id,
          parentRowId: row.parentId
        }
      });

      asyncRequest({
        url: reorderRowsUrl,
        method: 'PUT',
        data: body,
        callback: function(response: jsendResponse) {
          switch (response.status) {
            case 'success':
              dispatch(reorderSuccess(response.data as DangerousGoodPackage[]));
              runAutoCheck(autoCheckStatus)(dispatch);
            break;
            case 'unauthorised':
              dispatch(reorderFailure(response.status));
              alert('Take ownership of the DGD and try again.');
            break;
            default:
              dispatch(reorderFailure(response.status));
              alert('A problem has occured while saving the order of your rows. Try again, if the problem persists refresh the page.');
            break;
          }
        }
      });
    }
  }
