import { createSelector } from 'reselect';
import { flatten } from 'lodash';

const dangerousGoods = (dangerousGoods: DangerousGoodPackage[]) => dangerousGoods;

export const ocrBlocksFromDangerousGoods = createSelector(
  [dangerousGoods],
  (dangerousGoods: DangerousGoodPackage[]) : OcrBlockWithDgRowId[] => {

    let ocrBlocks = [];
    var blocksArr = dangerousGoods.map((good, i) => {
      if (!good.ocrLocation || !good.ocrLocation.blocks)
        return [];

      return good.ocrLocation.blocks.map((block) => {
        return {
          ...block,
          id: good.id
        } as OcrBlockWithDgRowId
      })
    })

    return flatten(blocksArr);
  }
)
