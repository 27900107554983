import * as React from 'react';
import * as CheckActions from 'checkspa/actions/check';
import { ocrBlocksFromDangerousGoods } from 'checkspa/selectors/dgd-viewer';
import { ImageViewer } from './ImageViewer';
import { Download } from 'library/icons';
// TODO: It would be nice to replace react-container-dimensions with useSize and remove this component
import ContainerDimensions from 'react-container-dimensions';

declare namespace DgdViewer {
  export interface Props {
    dgdImages: Array<DgdPreviewImage>;
    dangerousGoods: DangerousGoodPackage[];
    dgdViewerStatus: DgdViewerStatus;
    actions?: typeof CheckActions;
    pdfDownloadUrl?: string;
  }

  export interface State {
  }
}

function createImagePager(currentPage: number, maxPageNumbersToDisplay: number, dgdImages: DgdPreviewImage[]) {
  //calculate start and end of range of page numbers
  var firstPageToDisplay = 1;
  var lastPageToDisplay = dgdImages.length;
  var pageNumbersToDisplay = lastPageToDisplay;
  const allPagesNotVisible = dgdImages.length > maxPageNumbersToDisplay;

  if (allPagesNotVisible)
  {
    // cannot fit all pages into pager
    firstPageToDisplay = Math.ceil(currentPage - maxPageNumbersToDisplay / 2);

    if (firstPageToDisplay < 1)
    {
      firstPageToDisplay = 1;
    }

    pageNumbersToDisplay = maxPageNumbersToDisplay;
    lastPageToDisplay = firstPageToDisplay + pageNumbersToDisplay - 1;

    if (lastPageToDisplay > dgdImages.length)
    {
      firstPageToDisplay = dgdImages.length - maxPageNumbersToDisplay + 1;
    }
  }

  return {
    helperNavigationRequired: allPagesNotVisible,
    firstPageEnabled: currentPage > 1 && allPagesNotVisible,
    previousPageEnabled: currentPage > 1 && allPagesNotVisible,
    pages: dgdImages
      .map((img, index) => {
        return {
          image: img,
          pageNumber: index + 1,
          active: index + 1 === currentPage
        }
      })
      .slice(firstPageToDisplay-1, lastPageToDisplay),
    nextPageEnabled: currentPage < dgdImages.length && allPagesNotVisible,
    lastPageEnabled: currentPage < dgdImages.length && allPagesNotVisible
  }
}

export class DgdViewer extends React.Component<DgdViewer.Props, DgdViewer.State> {

  changePage(event, pageNo) {
    const { actions } = this.props;

    event.preventDefault();

    actions.dgdViewerChangePage( pageNo );
  }

  helperPageNavigation(text, tooltip, pageNumber, enabled) {

    return (
      <li style={ enabled ? {} : { 'opacity': '0.3' } }>
        { enabled
          ? <a href="#" title={ tooltip } onClick={ (e) => this.changePage(e, pageNumber) }>
            { text }
            </a>
          : <span className="pagination__link" style={{ 'cursor': 'default' }} title={ tooltip + " disabled" }>{text}</span>
        }
      </li>
    );
  }

  render() {
    const { actions, dgdImages, dangerousGoods, dgdViewerStatus, pdfDownloadUrl } = this.props;
    const { pageNumber } = dgdViewerStatus;

    const blocks = ocrBlocksFromDangerousGoods(dangerousGoods);

    var currentImage = dgdImages.find((image, i) => (i+1) === pageNumber);
    const imagePager = createImagePager(pageNumber, 9, dgdImages);

    return (
      dgdImages.length ?
        <div className='dgd-viewer'>
          <div className="dgd-viewer_actions">
            { pdfDownloadUrl &&
              <a href={ pdfDownloadUrl } className="btn btn-sm btn-tertiary" title="Download PDF of this DGD" target="blank">
                <Download height={15} width={15} /> Download
              </a>
            }
            <ul className="pagination">
              { imagePager.helperNavigationRequired && this.helperPageNavigation("<<", "First page", 1, imagePager.firstPageEnabled) }
              { imagePager.helperNavigationRequired && this.helperPageNavigation("<", "Previous page", pageNumber-1, imagePager.previousPageEnabled) }
              {
                imagePager.pages.map(details =>
                  <li className={ details.active ? 'page-item active' : 'page-item' } key={ details.pageNumber }>
                    <a href="#" className="page-link" onClick={(event) => this.changePage(event, details.pageNumber)}>
                      { details.pageNumber }
                    </a>
                  </li>
                )
              }
              { imagePager.helperNavigationRequired && this.helperPageNavigation(">", "Next page", pageNumber+1, imagePager.nextPageEnabled) }
              { imagePager.helperNavigationRequired && this.helperPageNavigation(">>", "Last page", dgdImages.length, imagePager.lastPageEnabled) }
            </ul>
          </div>

          <div className='image-container'>
            <ContainerDimensions>
              { ({ width: elementWidth }) =>
                <ImageViewer key={ currentImage.url }
                    actions={actions}
                    blocks={ blocks }
                    blockSelected={ dgdViewerStatus.blockSelected }
                    dgdImage={ currentImage }
                    pageNumber={ pageNumber }
                    totalPages={ dgdImages.length }
                    showBlockControls={true}
                    containerWidth={ elementWidth }
                  />
                }
              </ContainerDimensions>
          </div>
        </div>
      :
        <div className='autocheck-dgr-viewer-loading'>
          <span className='loading-graphic-container'>
            No DGD available for review
          </span>
        </div>
    )
  }
}
