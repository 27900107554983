import { createAction } from 'redux-actions';
import { Actions } from 'checkspa/constants';
import { Dispatch } from 'redux';
import { RootState } from 'checkspa/reducers';
import { asyncRequest } from 'communication/async-request';

const runAutoCheckRequested = createAction(Actions.RUN_AUTOCHECK_REQUEST);
const runAutoCheckSuccess = createAction<RootState>(Actions.RUN_AUTOCHECK_SUCCESS);
const runAutoCheckFailed = createAction(Actions.RUN_AUTOCHECK_FAIL);

export function runAutoCheck(autoCheckStatus: RunAutoCheckStatus, runAnyway?: boolean, successCallback?: () => void): (dispatch: Dispatch<ReduxActions.Action<any>>) => void {
  return (dispatch: Dispatch<ReduxActions.Action<any>>) => {

    if (!autoCheckStatus.documentationStarted && !runAnyway) {
      // Do nothing, the DGD isn't ready for AutoChecking yet.
      return;
    }

    dispatch(runAutoCheckRequested());

    asyncRequest({
      url: autoCheckStatus.runAutoCheckUrl,
      callback: function(response: jsendResponse) {
        if (response.status == 'success') {
          dispatch(runAutoCheckSuccess(response.data));
          if (successCallback) {
            successCallback();
          }
        } else {
          dispatch(runAutoCheckFailed());
          alert('Problem re-running AutoCheck rules, please try again. If the problem persists, please contact an administrator');
        }
      }
    });
  }
}
