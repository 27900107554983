import { createSelector } from 'reselect';
import { getDgMenuItemStatusForRows } from './menu-item-status-for-row';
import {
  sectionTabsForPackagingArea,
  sectionTabsForDocumentationArea } from 'checkspa/selectors/sections';

export const dgdMenuItemStatusesForPackagingArea = createSelector(
  [ sectionTabsForPackagingArea ],
  ( sectionStatuses: SectionStatusMap
  ) : DgdMenuStatusMap => {

    return getDgMenuItemStatusForRows(sectionStatuses);
  }
)

export const dgdMenuItemStatusesForDocumentationArea = createSelector(
  [ sectionTabsForDocumentationArea ],
  ( sectionStatuses: SectionStatusMap
  ) : DgdMenuStatusMap => {

    return getDgMenuItemStatusForRows(sectionStatuses);
  }
)