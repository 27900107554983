import { handleActions } from 'redux-actions';
import * as Actions from '../constants/actions';
import { RootState } from './index';

const initialState: AutoCheckResponse = null;

export default handleActions<AutoCheckResponse, any>({
  [Actions.RUN_AUTOCHECK_SUCCESS]: (state, action) => {
    var autoCheckResponse = action.payload.autoCheckResponse as AutoCheckResponse;
    if (autoCheckResponse) {
      return autoCheckResponse;
    }

    return state;
  }
}, initialState);
