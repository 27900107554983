import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RootState } from 'checkspa/reducers';
import { Breadcrumbs, AcHeaderTabs, AcHeaderInformation, IsOwnerMessage, NotOwnerMessage, OwnershipPopup } from 'checkspa/ui/components';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { documentationAreaSummary, packagingAreaSummary } from 'checkspa/selectors/dgd-menu';
import { isOwner } from 'checkspa/selectors/acceptance-check';
import * as CheckActions from 'checkspa/actions/check';
import { verificationSteps } from 'checkspa/selectors/verification';
import { Dialog } from 'library/form/components';
import { Refresh } from 'library/icons';
import { uiSettings } from 'environment/settings';

declare namespace Check {
  export interface Props extends RouteComponentProps<any> {
    dgdData?: DgdData;
    children?: React.ReactNode;
    packagingAreaSummary?: { total: number, completed: number };
    documentationAreaSummary?: { total: number, completed: number };
    isOwner?: boolean;
    actions?: typeof CheckActions;
    apiUrls?: ApiUrls;
    applicationState?: ApplicationState;
    acceptanceCheckState?: AcceptanceCheckState;
    verificationStepSummary?: VerificationStepSummary;
    documentationCheckOnly?: boolean;
    autocheckResponse: AutoCheckResponse;
  }

  export interface State {
    /* empty */
  }
}

const hideHeaderStatuses = ['AwaitingFile', 'Queued', 'Scanning'] as AcceptanceCheckStatus[];

export class CheckComponent extends React.Component<Check.Props, Check.State> {

  render() {
    const { location, children,
      dgdData,
      packagingAreaSummary,
      documentationAreaSummary,
      acceptanceCheckState,
      actions,
      apiUrls,
      applicationState,
      verificationStepSummary,
      documentationCheckOnly,
      autocheckResponse,
      isOwner } = this.props;

    if (!dgdData) {
      return <div className="checklist-screen">
        <div>Loading...</div>
      </div>
    }

    var checkOwnershipFn = () => {
      actions.checkOwnership(apiUrls.checkOwnershipUrl);
    };

    var closePopup = () => {
      actions.closeOwnershipPopup();
    }

    var ownerMessage = (acceptanceCheckState.signedOffBy ?
      null
      :
      <>
        { isOwner ?
          <IsOwnerMessage acceptanceCheckState={ acceptanceCheckState } checkOwnership={ checkOwnershipFn } currentUser={ applicationState.user } />
          :
          <NotOwnerMessage acceptanceCheckState={ acceptanceCheckState } checkOwnership={ checkOwnershipFn } currentUser={ applicationState.user } canTakeControl={ applicationState.supplyChainActiveCheck && (applicationState.isThirdPartyValidator || applicationState.canAcceptanceCheck || applicationState.canValidate) } />
        }
      </>
    );

    return (
      <div className="checklist-screen">
        { applicationState.autoCheckRunning &&
          <Dialog type='full'>
            <div className="react-loading spin-graphic">
              <div className="react-loading__inner">
                <Refresh width={30} height={30} />
                <p>Running AutoCheck...</p>
              </div>
            </div>
          </Dialog>
        }
        <div className="checklist-breadcrumb-and-status">
          {
            uiSettings.showBreadcrumb ?
            <Breadcrumbs dgdData={ dgdData } dgdLibraryUrl={ apiUrls.goToDgdLibrary }  />
            :
            <ol className="breadcrumb autocheck-breadcrumb">
              <li className="active">{dgdData.referenceType}: {dgdData.reference}</li>
            </ol>
          }
          { ownerMessage }
        </div>

        <OwnershipPopup acceptanceCheckState={ acceptanceCheckState } applicationState={ applicationState } changeOwnership={ actions.changeOwnership } closePopup={ closePopup }/>
        {
          hideHeaderStatuses.indexOf(acceptanceCheckState.status) === -1 &&
          <>
            <AcHeaderInformation dgdData={dgdData} acceptanceCheckState={ acceptanceCheckState } autocheckResponse={ autocheckResponse } currentUser={ applicationState.user }  />
            <AcHeaderTabs
              applicationState={ applicationState }
              location={ location }
              packagingAreaSummary={ packagingAreaSummary }
              documentationAreaSummary={ documentationAreaSummary }
              acceptanceCheckState={ acceptanceCheckState }
              verificationStepSummary={ verificationStepSummary }
              documentationCheckOnly={ documentationCheckOnly }
              isOwner={ isOwner }
              dgdSourceType={ dgdData.dgdSourceType }
              dgdCanBeVerified={ dgdData.dgdCanBeVerified }
              />
          </>
        }
        { children }
      </div>
    );
  }
}

export const CheckWithRouter = withRouter(CheckComponent);
export const Check = connect(mapStateToProps, mapDispatchToProps)(CheckWithRouter) as any;

function mapStateToProps(state: RootState):Check.Props {
  return {
    dgdData: state.dgdData,
    packagingAreaSummary: packagingAreaSummary(state),
    documentationAreaSummary: documentationAreaSummary(state),
    isOwner: isOwner(state),
    applicationState: state.applicationState,
    acceptanceCheckState: state.acceptanceCheckState,
    verificationStepSummary: verificationSteps(state),
    documentationCheckOnly: state.autoCheckResponse.documentationCheckOnly,
    autocheckResponse: state.autoCheckResponse,
    apiUrls: state.apiUrls,
    location: null,
    history: null,
    match: null,
  };
}

function mapDispatchToProps(dispatch) : Partial<Check.Props> {
  return {
    actions: bindActionCreators(CheckActions as any, dispatch),
    location: null,
    history: null,
    match: null
  };
}
