import { RootState } from 'checkspa/reducers';
import { createSelector } from 'reselect';

const acceptanceCheckStateFn = (state: RootState) => state.acceptanceCheckState;

export const acceptanceCheckComplete = createSelector(
  [ acceptanceCheckStateFn ],
  (acceptanceCheckState: AcceptanceCheckState) : boolean => {

    const { signedOffBy } = acceptanceCheckState;

    if (signedOffBy)
      return true;

    return false;
  }
)
