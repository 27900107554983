import { createAction } from 'redux-actions';
import { Actions } from 'checkspa/constants';
import { Dispatch } from 'redux';
import { runAutoCheck } from 'checkspa/actions/async/RunAutoCheck';
import { asyncRequest } from 'communication/async-request';
import { nullSmartVerificationProperties } from "library/dgd/components/Editable/cleanDangerousGoodPackage";
import { isDangerousGood } from 'library/dgd';

const saveRowRequested = createAction<DangerousGoodPackage>(Actions.SAVE_ROW_REQUESTED);
const saveRowSuccess = createAction<any>(Actions.SAVE_ROW_SUCCESS);
const saveRowFailure = createAction<{ response: jsendResponse, row: DangerousGoodPackage}>(Actions.SAVE_ROW_FAIL);

export function updateRow(row: DangerousGoodPackage, saveUrl: string, autoCheckStatus: RunAutoCheckStatus): (dispatch: Dispatch<ReduxActions.Action<any>>) => void {

    return (dispatch: Dispatch<ReduxActions.Action<any>>) => {

      if (isDangerousGood(row)) { // Dirty code to remove the deleted packages before saving.
        row.packages = row.packages.filter(w => !(w as PackageInformationEditing).deleted);
      }

      row = nullSmartVerificationProperties(row)

      dispatch(saveRowRequested(row));

      asyncRequest({
        url: saveUrl,
        method: 'PUT',
        data: row,
        callback: function(response: jsendResponse) {
          if (response.status == 'success') {
            dispatch(saveRowSuccess(response.data));
            runAutoCheck(autoCheckStatus)(dispatch);
          } else {
            dispatch(saveRowFailure({ response, row }));
            alert('A problem has occured while saving the row. Try again, if the problem persists please contact an administrator.');
          }
        }
      });
    }
  }
