import { createStore, applyMiddleware, Store } from 'redux';
import { logger } from 'checkspa/middleware';
import thunk from 'redux-thunk'
import rootReducer, { RootState } from 'checkspa/reducers';

export function configureStore(initialState?: RootState): Store<RootState> {
  const create = window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()(createStore)
    : createStore;

  const createStoreWithMiddleware = applyMiddleware(thunk, logger)(create);

  const store = createStoreWithMiddleware(rootReducer, initialState) as Store<RootState>;

  if (module.hot) {
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers');
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}
