import { handleActions } from 'redux-actions';
import * as Actions from '../constants/actions';

const initialState: ApplicationState = null;

export default handleActions<ApplicationState, any>({
  [Actions.RUN_AUTOCHECK_REQUEST]: (state, action) => {
    return {
      ...state,
      autoCheckRunning: true
    };
  },
  [Actions.RUN_AUTOCHECK_FAIL]: (state, action) => {
    return {
      ...state,
      autoCheckRunning: false
    };
  },
  [Actions.RUN_AUTOCHECK_SUCCESS]: (state, action) => {
    if (action.payload)
      return {
        ...state,
        autoCheckRunning: false
      };

    return state;
  },
  [Actions.CHANGE_OWNERSHIP_SUCCESS]: (state, action) => {
    if (action.payload) {
      var applicationState = action.payload.applicationState as ApplicationState;

      if (state.ownershipCallback) {
        var callback = state.ownershipCallback;
        setTimeout(() => callback('OwnershipChanged'), 0);
      }

      return {
        ...state,
        canValidate: applicationState.canValidate,
        canAcceptanceCheck: applicationState.canAcceptanceCheck,
        ownershipActions: [],
        ownershipPopup: 'Closed',
        ownershipCallback: null
      };
    }

    return state;
  },
  [Actions.CLOSE_OWNERSHIP_POPUP]: (state, action) => {
    if (state.ownershipCallback) {
      var callback = state.ownershipCallback;
      setTimeout(() => callback('Closed'), 0);
    }

    return {
      ...state,
      ownershipPopup: 'Closed',
      ownershipCallback: null
    };
  },
  [Actions.CHECK_OWNERSHIP_REQUESTED]: (state, action) => {
    return {
      ...state,
      ownershipPopup: 'Loading',
      ownershipCallback: action.payload, // May be undefined
    };
  },
  [Actions.CHECK_OWNERSHIP_FAILED]: (state, action) => {
    if (state.ownershipCallback) {
      var callback = state.ownershipCallback;
      setTimeout(() => callback('Error'), 0);
    }

    return {
      ...state,
      ownershipPopup: 'Error',
      ownershipCallback: null
    };
  },
  [Actions.CHECK_OWNERSHIP_SUCCESS]: (state, action) => {
    if (action.payload && action.payload) {
      var applicationState = action.payload.applicationState as ApplicationState;
      return {
        ...state,
        canValidate: applicationState.canValidate,
        canAcceptanceCheck: applicationState.canAcceptanceCheck,
        ownershipActions: action.payload.ownershipActions,
        ownershipPopup: 'Ready'
      } as ApplicationState;
    }

    return state;
  },

  // SIGN OFF EVENTS
  [Actions.CHECK_SIGNOFF_PRESSED]: (state, action) => {

    return {...state, savingSignOff: true} as ApplicationState
  },
  [Actions.CHECK_SIGNOFF_ERROR]: (state, action) => {

    return {...state, savingSignOff: false} as ApplicationState
  },
}, initialState);

