import { useSelector } from "react-redux"
import { RootState } from "checkspa/reducers"


function isIE() {

  return navigator && (
    (navigator.appName == 'Microsoft Internet Explorer') ||
    ((navigator.appName == 'Netscape') && (new RegExp("Trident/.*rv:([0-9]{1,}[\.0-9]{0,})").exec(navigator.userAgent) != null))
  );
}

export const useSmartVerification = () => {
  if (isIE())
    return false;

  const data = useSelector((state: RootState) => state.features.smartVerification);

  return data;
}
