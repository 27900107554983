import { RootState } from 'checkspa/reducers';
import { createSelector } from 'reselect';
import { answerKey } from './answer-key';
import { answersMap } from './answers-map';

export const getAnswerFor = createSelector(
  [ answersMap ],
  ( answers: UserSelectionMap) : UserSelectionFromRuleAndRowId => {
    return (ruleIdentifier: string, rowId: string) => {
      return answers[answerKey(ruleIdentifier, rowId)];
    }
  }
)