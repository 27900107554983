import { PackageInformationSv, DangerousGoodSv } from "./models";

export function nullSmartVerificationProperties(row: DangerousGoodPackage) {
  switch (row.type) {
    case 'DangerousGood':
      var dg = row as DangerousGoodSv;

      return {
        ...row,
        unNumberSv: null,
        properShippingNameSv: null,
        classSv: null,
        packingGroupSv: null,
        packingInstructionSv: null,
        authorizationSv: null,
        packages: dg.packages.map(m => ({...m, fullDescriptionSv: null} as PackageInformationSv))
      };
  }

  return row;
}
