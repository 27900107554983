import * as React from 'react';
import * as CheckActions from 'checkspa/actions/check';
import { Refresh, ExclamationMark } from 'library/icons';

declare namespace DgrReference {
  export interface Props {
    actions?: typeof CheckActions;
    dgrStatus: DgrReferenceStatus;
  }

  export interface State {
  }
}

export class DgrReference extends React.Component<DgrReference.Props, DgrReference.State> {
  render() {
    const { dgrStatus } = this.props;

    // TODO: strip out links
    const bodyText = { __html: dgrStatus.body };

    if (dgrStatus.loading) {
      return (
        <div className='autocheck-dgr-viewer-loading'>
          <span className='spin-graphic loading-graphic-container'>
            <Refresh height={20} width={20} />
            Loading
          </span>
        </div>
      )
    }

    if (dgrStatus.error) {
      return (
        <div className='autocheck-dgr-viewer-loading'>
          <span className='loading-graphic-container'>
            <ExclamationMark height={20} width={20} />
            An unexpected problem has occurred.
          </span>
        </div>
      )
    }

    return (
      <div className={'autocheck-dgr-viewer dgr-content'}>
        <p><span className={'badge badge-sm badge-border badge-link--' + dgrStatus.type}>{dgrStatus.id}</span></p>

        <h4 className={'autocheck-dgr-viewer__title'}>
          {dgrStatus.title}
        </h4>

        <div className={'autocheck-dgr-viewer__body'} dangerouslySetInnerHTML={ bodyText }></div>
      </div>
    )
  }
}
