import * as React from 'react';
import { UsersName } from 'checkspa/ui/components'
import { ExclamationMark } from 'library/icons';
import { FormattedRelative } from 'checkspa/ui/components/FormattedRelative';

declare namespace NotOwnerMessage {
  export interface Props {
    checkOwnership: () => void;
    acceptanceCheckState: AcceptanceCheckState;
    currentUser: UserDetails;
    canTakeControl: boolean;
  }

  export interface State {
    launchPopup: boolean;
  }
}

export class NotOwnerMessage extends React.Component<NotOwnerMessage.Props, NotOwnerMessage.State> {
  constructor(props) {
    super(props);

    this.state = {
      launchPopup: false
    }
  }

  render() {
    const { acceptanceCheckState, checkOwnership, currentUser, canTakeControl } = this.props;

    const ownedByMessage = acceptanceCheckState.ownedBy 
          ? <span>This DGD is currently owned by <strong><UsersName user={ acceptanceCheckState.ownedBy } currentUser={ currentUser } noUserText='Never edited' /></strong>.</span>
          : <span>This DGD is <strong>not</strong> owned by anyone.</span>

    return (
      <div className="dgd-read-only-msg">
        <div className="bg-warning">
          <ExclamationMark width={ 20 } height={ 20 } />
          <p>
            {
              canTakeControl ? ownedByMessage : <span>You do not have edit rights for this DGD/Office.</span>
            }
            {
             canTakeControl && acceptanceCheckState.lastUpdated &&
              <>
                { " " }
                <strong><UsersName user={ acceptanceCheckState.lastUpdatedBy } currentUser={ currentUser } /></strong>
                { " last updated it " }
                <strong><FormattedRelative value={ acceptanceCheckState.lastUpdated } /></strong>.
              </>
            }
          </p>
          { 
            canTakeControl &&          
            <button className="btn btn-tertiary btn-sm" onClick={ e => checkOwnership() } >
              Take control
            </button>
          }
        </div>
      </div>
    )
  }
}
