import { handleActions, Action } from 'redux-actions';
import * as Actions from '../constants/actions';

const initialState: DgrReferenceStatus = null;

export default handleActions<DgrReferenceStatus, any>({
  [Actions.DGR_VIEWER_LOADING]: (state, actions) => {
    // rightSidebar.ts updates the right panel status so the dgr panel shows on the DGR tab
    return {...state,
      loading: true,
      error: false
    } as DgrReferenceStatus
  },
  [Actions.DGR_VIEWER_SUCCESS]: (state, action) => {
    const { id, body, title, type } = action.payload;

    return {...state,
      id: id,
      body: body,
      title: title,
      type: type,
      loading: false,
      error: false
    } as DgrReferenceStatus
  },

  [Actions.DGR_VIEWER_CANCEL]: (state, action) => {
    return {...state,
      id: null,
      body: null,
      title: null,
      type: null,
      loading: false,
      error: false
    } as DgrReferenceStatus
  },

  [Actions.DGR_VIEWER_ERROR]: (state, action) => {
    return {...state,
      id: null,
      body: null,
      title: null,
      type: null,
      loading: false,
      error: true
    } as DgrReferenceStatus
  }
}, initialState);
