export const SECTION_NOT_COMPLETE = 'SECTION_NOT_COMPLETE';

export const QUESTION_OPTION_PRESSED = 'QUESTION_OPTION_CLICKED';
export const QUESTION_OPTION_PRESSED_CONFIRMED = 'QUESTION_OPTION_CLICKED_CONFIRMED';
export const QUESTION_OPTION_PRESSED_ERROR = 'QUESTION_OPTION_CLICKED_ERROR';

export const AC_POLLING_STATUS_UPDATE = 'AC_POLLING_STATUS_UPDATE';

export const DOCUMENTATION_SETUP_REQUEST = 'DOCUMENTATION_SETUP_REQUEST';
export const DOCUMENTATION_SETUP_SUCCESS = 'DOCUMENTATION_SETUP_CONFIRMED';
export const DOCUMENTATION_SETUP_ERROR = 'DOCUMENTATION_SETUP_ERROR';

export const DOCUMENTATION_COMPLETED_REQUEST = 'DOCUMENTATION_COMPLETED_REQUEST';
export const DOCUMENTATION_COMPLETED_CONFIRMED = 'DOCUMENTATION_COMPLETED_CONFIRMED';
export const DOCUMENTATION_COMPLETED_ERROR = 'DOCUMENTATION_COMPLETED_ERROR';

export const VALIDATE_PROPERTY_REQUEST = 'VALIDATE_PROPERTY_REQUEST';
export const VALIDATE_PROPERTY_CONFIRMED = 'VALIDATE_PROPERTY_CONFIRMED';
export const VALIDATE_PROPERTY_ERROR = 'VALIDATE_PROPERTY_ERROR';

export const VALIDATE_DGD_REQUEST = 'VALIDATE_DGD_REQUEST';
export const VALIDATE_DGD_CONFIRMED = 'VALIDATE_DGD_CONFIRMED';
export const VALIDATE_DGD_ERROR = 'VALIDATE_DGD_ERROR';

export const CHECK_SIGNOFF_PRESSED = 'CHECK_SIGNOFF_PRESSED';
export const CHECK_SIGNOFF_CONFIRMED = 'CHECK_SIGNOFF_CONFIRMED';
export const CHECK_SIGNOFF_ERROR = 'CHECK_SIGNOFF_ERROR';

export const REPORT_REQUESTED = 'REPORT_REQUESTED';
export const REPORT_RECEIVED = 'REPORT_RECEIVED';
export const REPORT_FAILED = 'REPORT_FAILED';

export const SET_HINT_SAVING = 'SET_HINT_SAVING';
export const SET_HINT_CONFIRMED = 'SET_HINT_CONFIRMED';
export const SET_HINT_ERROR = 'SET_HINT_ERROR';

export const UPDATE_NOTE_PRESSED = 'UPDATE_NOTE_PRESSED';
export const UPDATE_NOTE_CONFIRMED = 'UPDATE_NOTE_CONFIRMED';
export const UPDATE_NOTE_ERROR = 'UPDATE_NOTE_ERROR';

export const SAVE_ROW_REQUESTED = 'SAVE_ROW_REQUESTED';
export const SAVE_ROW_SUCCESS = 'SAVE_ROW_SUCCESS';
export const SAVE_ROW_FAIL = 'SAVE_ROW_FAIL';

export const DELETE_ROW_REQUESTED = 'DELETE_ROW_REQUESTED';
export const DELETE_ROW_SUCCESS = 'DELETE_ROW_SUCCESS';
export const DELETE_ROW_FAIL = 'DELETE_ROW_FAIL';

export const ADD_ROWS_REQUESTED = 'ADD_ROWS_REQUESTED';
export const ADD_ROWS_SUCCESS = 'ADD_ROWS_SUCCESS';
export const ADD_ROWS_FAIL = 'ADD_ROWS_FAIL';

export const RUN_AUTOCHECK_REQUEST = 'RUN_AUTOCHECK_REQUEST';
export const RUN_AUTOCHECK_SUCCESS = 'RUN_AUTOCHECK_SUCCESS';
export const RUN_AUTOCHECK_FAIL = 'RUN_AUTOCHECK_FAIL';

export const CHANGE_OWNERSHIP_REQUESTED = 'CHANGE_OWNERSHIP_REQUESTED';
export const CHANGE_OWNERSHIP_SUCCESS = 'CHANGE_OWNERSHIP_SUCCESS';
export const CHANGE_OWNERSHIP_FAILED = 'CHANGE_OWNERSHIP_FAILED';

export const CLOSE_OWNERSHIP_POPUP = 'CLOSE_OWNERSHIP_POPUP';

export const CHECK_OWNERSHIP_REQUESTED = 'CHECK_OWNERSHIP_REQUESTED';
export const CHECK_OWNERSHIP_SUCCESS = 'CHECK_OWNERSHIP_SUCCESS';
export const CHECK_OWNERSHIP_FAILED = 'CHECK_OWNERSHIP_FAILED';

export const SAVE_REORDER_ROWS_REQUEST = 'SAVE_REORDER_ROWS_REQUESTED';
export const SAVE_REORDER_ROWS_SUCCESS = 'SAVE_REORDER_ROWS_SUCCESS';
export const SAVE_REORDER_ROWS_FAIL = 'SAVE_REORDER_ROWS_FAIL';

export const ENABLE_DEFAULT_RESULTS = 'ENABLE_DEFAULT_RESULTS';
export const DISABLE_DEFAULT_RESULTS = 'DISABLE_DEFAULT_RESULTS';

export const CHANGE_RULE_TYPE_VISIBILITY = 'CHANGE_RULE_TYPE_VISIBILITY';

export const VALIDATE_ROW_PRESSED = 'VALIDATE_ROW_PRESSED';
export const VALIDATE_ROW_CONFIRMED = 'VALIDATE_ROW_CONFIRMED';
export const VALIDATE_ROW_ERROR = 'VALIDATE_ROW_ERROR';

export const DGD_VIEWER_FOCUS = 'DGD_VIEWER_FOCUS';
export const DGD_VIEWER_FOCUS_ID = 'DGD_VIEWER_FOCUS_ID';
export const DGD_VIEWER_CANCEL = 'DGD_VIEWER_CANCEL';
export const DGD_VIEWER_CHANGE_PAGE = 'DGD_VIEWER_PAGE';

export const SWITCH_CHECKLIST_VIEW = 'SWITCH_CHECKLIST_VIEW';

export const DGR_VIEWER_LOADING = 'DGR_VIEWER_LOADING';
export const DGR_VIEWER_SUCCESS = 'DGR_VIEWER_SUCCESS';
export const DGR_VIEWER_ERROR = 'DGR_VIEWER_ERROR';
export const DGR_VIEWER_CANCEL = 'DGR_VIEWER_CANCEL';

export const DGD_PREVIEW_AREA_TOGGLE = 'DGD_PREVIEW_AREA_SHOW';
export const DGD_PREVIEW_CLOSE = 'DGD_PREVIEW_CLOSE';
export const RIGHT_SIDEBAR_TOGGLE = 'RIGHT_SIDEBAR_TOGGLE';
export const RIGHT_SIDEBAR_TAB_CHANGE = 'RIGHT_SIDEBAR_TAB_CHANGE';
export const RIGHT_SIDEBAR_CHANGE_WIDTH = 'RIGHT_SIDEBAR_CHANGE_WIDTH';
export const RIGHT_SIDEBAR_DOCK_TOGGLE = 'RIGHT_SIDEBAR_DOCK_TOGGLE';

export const SUBMIT_RESOURCE = 'SUBMIT_RESOURCE';
export const ADD_RESOURCE_SUCCESS = 'ADD_RESOURCE_SUCCESS';
export const LOAD_RESOURCE_SECTIONS = 'LOAD_RESOURCE_SECTIONS';
export const DELETE_RESOURCE_SECTION = 'DELETE_RESOURCE_SECTION';
export const DGD_RESOURCES_EDIT = 'DGD_RESOURCES_EDIT';

export const AC_SYNC_COMPLETED = 'AC_SYNC_COMPLETED';
