import { RootState } from 'checkspa/reducers';
import { createSelector } from 'reselect';
import { inferSectionStatus } from 'checkspa/selectors/sections/infer-section-status';
import { dgdRowsForPackagingArea, dgdRowsForDocumentationArea } from 'checkspa/selectors/dgd-rows/dgd-rows';
import { getAnswerFor } from 'checkspa/selectors/user-selections';
import { checklistQuestionMap } from 'checkspa/selectors/questions';

const documentationArea = (state: RootState) => state.autoCheckResponse.documentation;
const packagingArea = (state: RootState) => state.autoCheckResponse.packaging;

const sectionTabsForArea = (dgdRows: DangerousGoodPackage[],
    checkAreaForRows : CheckAreaForRows,
    getAnswerFor: UserSelectionFromRuleAndRowId,
    checklistQuestionsMap: ChecklistQuestionMap
  ) : SectionStatusMap => {

  let map = {} as SectionStatusMap;

  const keys = Object.keys(checkAreaForRows);

  for (var key of keys) {
    const area = checkAreaForRows[key];
    const sections = area.sections;

    var sectionTabs = sections.map(section => {
      let summary = inferSectionStatus(section.questions, checklistQuestionsMap, getAnswerFor);

      return {
        section: section,
        status: summary.status,
        automatedFailures: summary.automatedFailures,
        saving: summary.saving
      } as SectionTab;
    });

    map[key] = sectionTabs;
  }

  return map;
}

export const sectionTabsForPackagingArea = createSelector(
  [ packagingArea, getAnswerFor, dgdRowsForPackagingArea, checklistQuestionMap ],
  ( checkAreaForRows : CheckAreaForRows,
    getAnswerFor: UserSelectionFromRuleAndRowId,
    dgdRows: DangerousGoodPackage[],
    checklistQuestionsMap: ChecklistQuestionMap
  ) : SectionStatusMap => {

    return sectionTabsForArea(dgdRows, checkAreaForRows, getAnswerFor, checklistQuestionsMap);
  }
)

export const sectionTabsForPackagingAreaForRowId = (rootState: RootState, rowId: string) : SectionTab[] => {
  return sectionTabsForPackagingArea(rootState)[rowId];
}

export const sectionTabsForDocumentationArea = createSelector(
  [ documentationArea, getAnswerFor, dgdRowsForDocumentationArea, checklistQuestionMap ],
  ( checkAreaForRows : CheckAreaForRows,
    getAnswerFor: UserSelectionFromRuleAndRowId,
    dgdRows: DangerousGoodPackage[],
    checklistQuestionsMap: ChecklistQuestionMap
  ) : SectionStatusMap => {

    return sectionTabsForArea(dgdRows, checkAreaForRows, getAnswerFor, checklistQuestionsMap);
  }
)

export const sectionTabsForDocumentationAreaForRowId = (rootState: RootState, rowId: string) : SectionTab[] => {
  return sectionTabsForDocumentationArea(rootState)[rowId];
}
