import * as React from 'react';
import { UsersName } from 'checkspa/ui/components'
import { User } from 'library/icons';
import { FormattedRelative } from 'checkspa/ui/components/FormattedRelative';

declare namespace IsOwnerMessage {
  export interface Props {
    checkOwnership: () => void;
    acceptanceCheckState: AcceptanceCheckState;
    currentUser: UserDetails;
  }

  export interface State {
    launchPopup: boolean;
  }
}

export class IsOwnerMessage extends React.Component<IsOwnerMessage.Props, IsOwnerMessage.State> {
  constructor(props) {
    super(props);

    this.state = {
      launchPopup: false
    }
  }

  render() {
    const { acceptanceCheckState, checkOwnership, currentUser } = this.props;

    return (
      <div className="dgd-read-only-msg">
        <div className="bg-success">
          <User width={ 20 } height={ 20 } />
          <p>
            You have control of this DGD.
            { " " }
            <UsersName user={ acceptanceCheckState.lastUpdatedBy } currentUser={ currentUser } noUserText='Never edited' />
            { " last updated it " }
            <strong><FormattedRelative value={ acceptanceCheckState.lastUpdated } /></strong>.
          </p>
          <button className="btn btn-tertiary btn-sm" onClick={ e => checkOwnership() } >
            Release control
          </button>
        </div>
      </div>
    )
  }
}
