import { RootState } from 'checkspa/reducers';
import { createSelector } from 'reselect';
import { UrlTemplates } from 'checkspa/constants';

const dgdFn = (state: RootState) => state.dgdData;
const validationLinksFn = (state: RootState) => state.questionToValidationLinks;

export const verificationSteps = createSelector(
  [ dgdFn,
    validationLinksFn ],
  (dgd: DgdData, validationLinks: QuestionToValidationLink[]) : VerificationStepSummary => {

    var rowSteps = dgd.dangerousGoods.map((row, index) : VerificationStep => {
      return {
        id: row.id,
        url: UrlTemplates.verifyRowTemplate.expand({ id: row.id }),
        name: 'Row ' + (index + 1),
        validatedOn: row.validatedOn,
        validatedBy: row.validatedBy
      };
    });

    var beforeRows = [
      referencesStep(dgd),
      getPropertyVerificationStep('shipper', validationLinks, dgd),
      getPropertyVerificationStep('consignee', validationLinks, dgd),
      aircraftAndShipmentStep(dgd),
      departureAndDestinationStep(dgd),
    ];
    var afterRows = [
      //getPropertyVerificationStep('additionalHandlingInformation', validationLinks, dgd)
    ];

    return {
      allSteps: beforeRows.concat(rowSteps, afterRows),
      stepsBeforeRows: beforeRows,
      rowSteps,
      stepsAfterRows: afterRows
    };
  }
)

function referencesStep(dgd: DgdData) {

  return {
    id: 'references',
    url: UrlTemplates.verifyReferences,
    validatedOn: dgd.awbMeta ? dgd.awbMeta.validatedOn : null,
    validatedBy: dgd.awbMeta ? dgd.awbMeta.validatedBy : null,
    name: 'AWB & References'
  } as VerificationStep
}

function aircraftAndShipmentStep(dgd: DgdData) {

  return {
    id: 'aircraft-and-shipment',
    url: UrlTemplates.verifyAircraftAndShipment,
    validatedOn: dgd.aircraftTypeMeta ? dgd.aircraftTypeMeta.validatedOn : null,
    validatedBy: dgd.aircraftTypeMeta ? dgd.aircraftTypeMeta.validatedBy : null,
    name: 'Aircraft & Shipment Type'
  } as VerificationStep
}

function departureAndDestinationStep(dgd: DgdData) {

  var validatedOn = null;
  var validatedBy = null;

  if (dgd.originMeta.validatedOn && dgd.destinationMeta.validatedOn) {
    validatedOn = dgd.originMeta.validatedOn;
  }

  if (dgd.originMeta.validatedBy && dgd.destinationMeta.validatedBy) {
    validatedBy = dgd.originMeta.validatedBy;
  }

  return {
    id: 'origin-destination',
    url: UrlTemplates.verifyAirports,
    validatedOn: validatedOn,
    validatedBy: validatedBy,
    name: 'Departure & Destination'
  } as VerificationStep
}

function getPropertyVerificationStep(propertyName: string, validationLinks: QuestionToValidationLink[], dgd: DgdData) {

  var validationLink = validationLinks.find(w => w.dgdPropertyName == propertyName);
  var details = getValidationDetails(validationLink, dgd);

  return {
    id: propertyName,
    url: UrlTemplates.verifyPropertyTemplate.expand({ propertyName: propertyName }),
    validatedOn: details.meta.validatedOn,
    validatedBy: details.meta.validatedBy,
    name: validationLink.title
  } as VerificationStep;
}

function getValidationDetails(questionToValidation: QuestionToValidationLink, dgd: DgdData) {
  if (!questionToValidation || !dgd)
    return null;

  const propertyName = questionToValidation.dgdPropertyName;
  let propertyValue = dgd[propertyName] as string;
  const meta = dgd[propertyName + "Meta"] as DgdValueMeta;
  propertyValue = propertyValue ? propertyValue : '';
  const image = dgd.sectionImages.find(w => w.id === questionToValidation.previewArea);

  return {
    name: propertyName,
    meta: meta,
    value: propertyValue,
    image: image
  }
}
