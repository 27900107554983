import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { RootState } from 'checkspa/reducers';
import { UrlTemplates } from 'checkspa/constants/index';
import { uiSettings } from 'environment/settings';
import usePolling from 'checkspa/hooks/usePolling';
import { acceptanceCheckPollingStatusUpdate } from 'checkspa/actions/check';
import { Refresh } from 'library/icons';
import { Suspense } from 'react';
import { LoadingMessage } from 'library/loader';
import { DateUi } from 'library/form/components';
import { RightSidebar } from 'checkspa/ui/containers/RightSidebar/RightSidebar';
import { resourceDownloadUrl } from 'checkspa/constants/urls';

const UploadDgdAsync = React.lazy(() => import(/* webpackChunkName: "UploadDgd" */'./upload-dgd')
  .then(({ UploadDgd }) => ({ default: UploadDgd }))
);

declare namespace Landing {
  export interface StoreProps {
    acceptanceCheckState: AcceptanceCheckState;
    applicationState: ApplicationState;
    apiUrls: ApiUrls;
    dgdData: DgdData;
  }
}

export function Landing() {

  const storeProps = useSelector(mapStateToProps)
  const dispatch = useDispatch();
  const status = storeProps.acceptanceCheckState.status;

  const { acceptanceCheckState, applicationState, dgdData, apiUrls } = storeProps;

  if (acceptanceCheckState.signedOffBy) {
    return <Redirect to={ UrlTemplates.reportUrl }/>;
  }

  if (acceptanceCheckState.documentationCompletedOn && applicationState.canAcceptanceCheck) {
    return <Redirect to={ UrlTemplates.packagingTemplate.expand({}) }/>;
  }

  if (acceptanceCheckState.verificationCompletedOn && applicationState.canAcceptanceCheck && !applicationState.isDgDigitalSubscription) {
    return <Redirect to={ UrlTemplates.documentationTemplate.expand({}) }/>;
  }

  if (uiSettings.awaitDgdContents) {

    const pauseForStatuses = ['AwaitingFile', 'Queued', 'Scanning', 'ProcessingError', 'ImportFailure'] as AcceptanceCheckStatus[];
    const statusRenderFn = {
      'AwaitingFile': <RenderAwaitingFile />,
      'Queued': <DgdScanQueued />,
      'Scanning': <ScanningDgd />,
      'ProcessingError': <DgdProcessingError />,
      'ImportFailure': <DgdImportFailure />
    } as { [key: string]: React.ReactElement };

    usePolling({
      url: apiUrls.statusPollingUrl,
      interval: 3000,
      onSuccess: (response) => {
        switch (response.status) {
          case 'success':
            const newStatus = response.data.acceptanceCheckState.status;

            if (newStatus !== status) {
              dispatch(acceptanceCheckPollingStatusUpdate(response.data))
            }

            // Continue polling if we have pausing status
            return pauseForStatuses.indexOf(newStatus) > -1;
          default:
            alert('An unexpected error has occured, please refresh the page');
            // TODO: What to do when an error occurs?
            return false; // Stop polling, an error has occurred
        }
      },
      method: 'GET',
    });

    if (pauseForStatuses.indexOf(status) > -1) {
      return (
        <div className="awaiting-dgd">
          { statusRenderFn[status] }
          { status === 'AwaitingFile' &&
            <Suspense fallback={<LoadingMessage />}>
              <UploadDgdAsync uploadDgdUrl={ apiUrls.uploadDgdUrl } />
            </Suspense>
          }
        </div>
      )
    }
  }

  return <Redirect to={ UrlTemplates.verifyOrDgdTab(dgdData.dgdCanBeVerified) }/>;
}

function StatusScreen({title, status, children}: { title: string, status: AcceptanceCheckStatus, children?: React.ReactElement | React.ReactElement[] }) {
  return (
    <div>
      <h1 data-testid={ `status-for-${status}` }>{title}</h1>
      { children }
    </div>
  );
}

function RenderAwaitingFile() {
  return (
    <StatusScreen title="Waiting for DGD" status="AwaitingFile">
      <p>
        This Acceptance Check does not have any DGD information associated to it yet. Keep an eye on this screen and we'll keep you up to date with what we know.
      </p>
    </StatusScreen>
  );
};

function DgdScanQueued() {
  return (
    <StatusScreen title="DGD queued for scanning" status="Queued">
      <Spinner />
      <DisplayDgdSource />
    </StatusScreen>
  );
};

function ScanningDgd() {
  return (
    <StatusScreen title="Scanning DGD" status="Scanning">
      <p>
        We appreciate your patience while the file is being scanned.
      </p>
      <Spinner />
      <DisplayDgdSource />
    </StatusScreen>
  );
};

function DgdProcessingError() {
  return (
    <div className="content-sidebar-wrapper right-sidebar--adrift">
      <StatusScreen title="Processing error while scanning" status="ProcessingError">
        <p>
          A problem has occurred while scanning the DGD.
        </p>
        <DisplayDgdSource />
      </StatusScreen>

      <RightSidebar allowDocking={ false } renderReferenceArea={ false } dockingPossible={ false } renderResourcesArea={ false } />
    </div>
  );
};

function DgdImportFailure() {
  return (
    <div className="content-sidebar-wrapper right-sidebar--adrift">
      <StatusScreen title="Import failure after scanning complete" status="ImportFailure">
        <p>
          A problem has occurred while importing your scanned DGD.
        </p>
        <DisplayDgdSource />
      </StatusScreen>

      <RightSidebar allowDocking={ false } renderReferenceArea={ false } dockingPossible={ false } renderResourcesArea={ false } />
    </div>
  );
};

function DisplayDgdSource() {
  const status = useSelector((rootState: RootState) => rootState.acceptanceCheckState);
  const id = useSelector((rootState: RootState) => rootState.id);

  return (
    <dl>
      {
        status.dgdScanSourceFileId &&
        <>
          <div>
            <dt>File name:</dt>
            <dd>
              <a href={resourceDownloadUrl.expand({ id, fileId: status.dgdScanSourceFileId })} target="_blank">
                {status.dgdScanSourceFileName}
              </a>
            </dd>
          </div>
        </>
      }
      { status.dgdSourceEmailFrom ?
        <>
          <div>
            <dt>Emailed by:</dt>
            <dd>{status.dgdSourceEmailFrom}</dd>
          </div>
          <div>
            <dt>Email subject line:</dt>
            <dd>{status.dgdSourceEmailSubject}</dd>
          </div>
          <div>
            <dt>Received:</dt>
            <dd>{ status.dgdSourceCreatedOnFormattedOfficeTz && <DateUi date={status.dgdSourceCreatedOn} timezoneType='office' showTime showOffset /> }</dd>
          </div>
        </>
        :
        <>
          <div>
            <dt>Uploaded by:</dt>
            <dd>{status.dgdSourceCreatedBy}</dd>
          </div>
          <div>
            <dt>Uploaded:</dt>
            <dd>{ status.dgdSourceCreatedOnFormattedOfficeTz && <DateUi date={status.dgdSourceCreatedOn} timezoneType='office' showTime showOffset /> }</dd>
          </div>
        </>
      }
      <div>
        <dt>Office:</dt>
        <dd>{status.dgdSourceOfficeName}</dd>
      </div>
    </dl>
  );
}

function Spinner() {
  return (
    <div className="react-loading spin-graphic">
      <div className="react-loading__inner">
        <Refresh width={50} height={50} />
      </div>
    </div>
  );
}

function mapStateToProps(state: RootState) {
  return {
    acceptanceCheckState: state.acceptanceCheckState,
    applicationState: state.applicationState,
    apiUrls: state.apiUrls,
    dgdData: state.dgdData,
  } as Landing.StoreProps;
}
