import { createAction } from 'redux-actions';
import { Actions } from '../../constants';
import { Dispatch } from 'redux';
import { asyncRequest } from 'communication/async-request';

const questionOptionInitialPress = createAction<UserSelection>(Actions.QUESTION_OPTION_PRESSED);
const questionOptionConfirmed = createAction<UserSelection>(Actions.QUESTION_OPTION_PRESSED_CONFIRMED);
const questionOptionFailed = createAction<UserSelection>(Actions.QUESTION_OPTION_PRESSED_ERROR);

export function questionOptionPressed(rowId: string, identifier: string, value: RuleOutput, saveUrl: string): (dispatch: Dispatch<ReduxActions.Action<any>>) => void {

  return (dispatch: Dispatch<ReduxActions.Action<any>>) => {

    var userSelection = {
      rowId: rowId,
      identifier: identifier,
      value: value,
      deleteAnswerUrl: null
    } as UserSelection;

    dispatch(questionOptionInitialPress(userSelection));

    var body = {...userSelection, response: userSelection.value };

    asyncRequest({
      url: saveUrl,
      method: 'POST',
      data: body,
      callback: function(response: jsendResponse) {
        if (response.status == 'success') {
          dispatch(questionOptionConfirmed(response.data as UserSelection));
          return;
        }

        dispatch(questionOptionFailed(userSelection));
        if (response.status != 'unauthorised') {
          alert('A problem occured when saving your response, it has been reset to what it was.');
        }
      }
    });
  }
}

export function questionAnswerDeselected(rowId: string, identifier: string, saveUrl: string): (dispatch: Dispatch<ReduxActions.Action<any>>) => void {

    return (dispatch: Dispatch<ReduxActions.Action<any>>) => {

      var userSelection = {
        rowId: rowId,
        identifier: identifier,
        value: null
      } as UserSelection;

      dispatch(questionOptionInitialPress(userSelection));

      asyncRequest({
        url: saveUrl,
        method: 'DELETE',
        callback: function(response: jsendResponse) {
          if (response.status == 'success') {
            dispatch(questionOptionConfirmed({
              ...response.data, // acceptanceCheckState info
              userSelection: userSelection
            }));
            return;
          }

          dispatch(questionOptionFailed(userSelection));
          if (response.status != 'unauthorised') {
            alert('A problem occured when saving your response, it has been reset to what it was.');
          }
        }
      });
    }
  }
