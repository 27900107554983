
export function isDangerousGood(x: DangerousGoodPackage): x is DangerousGood {
  return x.type === 'DangerousGood';
}

export function isAllPackedInOne(x: DangerousGoodPackage): x is AllPackedInOne {
  return x.type === 'AllPackedInOne';
}

export function isOverpack(x: DangerousGoodPackage): x is Overpack {
  return x.type === 'Overpack';
}
