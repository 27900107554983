import { createAction } from 'redux-actions';
import { Actions } from '../../constants';
import { Dispatch } from 'redux';

const rightSidebarToggle = createAction<RightSidebarState>(Actions.RIGHT_SIDEBAR_TOGGLE);
const rightSidebarTabChange = createAction<RightSidebarState>(Actions.RIGHT_SIDEBAR_TAB_CHANGE);
const rightSidebarChangeWidth = createAction<RightSidebarState>(Actions.RIGHT_SIDEBAR_CHANGE_WIDTH);
const rightSidebarDock = createAction<RightSidebarState>(Actions.RIGHT_SIDEBAR_DOCK_TOGGLE);

export function showRightSidebar( showSidebar: boolean ): (dispatch: Dispatch<ReduxActions.Action<RightSidebarState>>) => void {

  return (dispatch: Dispatch<ReduxActions.Action<RightSidebarState>>) => {
    let sidebar = {
      show: showSidebar
    } as RightSidebarState;

    dispatch(rightSidebarToggle( sidebar ));
  }
}

export function changeActiveTab( activeTab: string ): (dispatch: Dispatch<ReduxActions.Action<RightSidebarState>>) => void {

  return (dispatch: Dispatch<ReduxActions.Action<RightSidebarState>>) => {
    let sidebar = {
      activeTab: activeTab,
      show: true
    } as RightSidebarState;

    dispatch(rightSidebarTabChange( sidebar ));
  }
}

export function changeSidebarWidth( width: number ): (dispatch: Dispatch<ReduxActions.Action<RightSidebarState>>) => void {

  return (dispatch: Dispatch<ReduxActions.Action<RightSidebarState>>) => {
    let sidebar = {
      dockSidebarWidth: width
    } as RightSidebarState;

    dispatch(rightSidebarChangeWidth( sidebar ));
  }
}

export function dockSidebar( dock: boolean ): (dispatch: Dispatch<ReduxActions.Action<RightSidebarState>>) => void {

  return (dispatch: Dispatch<ReduxActions.Action<RightSidebarState>>) => {
    let sidebar = {
      dockSidebar: dock
    } as RightSidebarState;

    dispatch(rightSidebarDock( sidebar ));
  }
}
