import { createAction } from 'redux-actions';
import { Actions } from '../../constants';
import { Dispatch } from 'redux';
import { asyncRequest } from 'communication/async-request';

const reportRequested = createAction(Actions.REPORT_REQUESTED);
const reportReceived = createAction(Actions.REPORT_RECEIVED);
const reportFailed = createAction(Actions.REPORT_FAILED);

export function requestReport(reportUrl: string): (dispatch: Dispatch<ReduxActions.Action<any>>) => void {

  return (dispatch: Dispatch<ReduxActions.Action<any>>) => {

    dispatch(reportRequested());

    asyncRequest({
      url: reportUrl,
      callback: function(response: jsendResponse) {
        if (response.status != 'success') {
          dispatch(reportFailed());
          throw 'Problem getting the acceptance check report.';
        } else {
          dispatch(reportReceived(response.data)); // received JSON automatically listened for in the report reducer
        }
      }
    });
  }
}
