import { handleActions } from 'redux-actions';
import * as Actions from '../constants/actions';

const initialState: UserNotes = [];

export default handleActions<UserNotes, any>({

  [Actions.CHANGE_OWNERSHIP_SUCCESS]: (state, action) => {
    const { questionIdentifierNotesRemoved } = action.payload;
    if (questionIdentifierNotesRemoved && questionIdentifierNotesRemoved.length > 0) {
      return state.filter(w => questionIdentifierNotesRemoved.indexOf(w.identifier) == -1 );
    }

    return state;
  },

  [Actions.UPDATE_NOTE_PRESSED]: (state, action) => {
    const { rowId, identifier, notes } = action.payload as UserNote;

    return state.map(input => {
      if (input.identifier == identifier && input.rowId == rowId) {
        return {
          ...input,
          notes: notes,
          isSaving: true,
          isEditing: true
        };
      }
      return input;
    });

  },

  [Actions.UPDATE_NOTE_CONFIRMED]: (state, action) => {
    const { rowId, identifier, notes } = action.payload.note as UserNote;

    return state.map(input => {
      if (input.identifier == identifier && input.rowId == rowId) {
        return {
          ...input,
          isSaving: false,
          isEditing: false
        };
      }

      return input;
    });
  },

  [Actions.UPDATE_NOTE_ERROR]: (state, action) => {
    const { rowId, identifier, notes } = action.payload as UserNote;

    return state.map(input => {
      if (input.identifier == identifier && input.rowId == rowId) {
        return {
          ...input,
          isSaving: false
        };
      }

      return input;
    });
  }
}, initialState);
