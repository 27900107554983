import { createAction } from 'redux-actions';
import { Actions } from '../../constants';
import { Dispatch } from 'redux';
import { runAutoCheck } from './RunAutoCheck';
import { asyncRequest } from 'communication/async-request';

const addRowsRequested = createAction(Actions.ADD_ROWS_REQUESTED);
const addRowsSuccess = createAction<any>(Actions.ADD_ROWS_SUCCESS);
const addRowsFailed = createAction<any>(Actions.ADD_ROWS_FAIL);

export function addRows(rows: DangerousGoodPackage[], newRowsUrl: string, autoCheckStatus: RunAutoCheckStatus): (dispatch: Dispatch<ReduxActions.Action<any>>) => void {

  return (dispatch: Dispatch<ReduxActions.Action<any>>) => {

    dispatch(addRowsRequested());

    asyncRequest({
      url: newRowsUrl,
      method: 'POST',
      data: rows,
      callback: function(response: jsendResponse) {
        if (response.status != 'success') {
          dispatch(addRowsFailed(response.status));
        } else {
          dispatch(addRowsSuccess(response.data));
          runAutoCheck(autoCheckStatus)(dispatch);
        }
      }
    });
  }
}
