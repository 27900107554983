import { createAction } from 'redux-actions';
import { Actions } from '../../constants';
import { Dispatch } from 'redux';
import { runAutoCheck } from 'checkspa/actions/async/RunAutoCheck';
import { asyncRequest } from 'communication/async-request';

const validateDgdPropertyRequested = createAction<ValidateDgdPropertyRequest>(Actions.VALIDATE_PROPERTY_REQUEST);
const validateDgdPropertyConfirmed = createAction<ValidateDgdPropertyRequest>(Actions.VALIDATE_PROPERTY_CONFIRMED);
const validateDgdPropertyError = createAction<ValidateDgdPropertyRequest>(Actions.VALIDATE_PROPERTY_ERROR);

export function validateDgdProperty(properties: ValidateDgdProperty[], validateSaveUrl: string, autoCheckStatus: RunAutoCheckStatus): (dispatch: Dispatch<ReduxActions.Action<any>>) => void {

  return (dispatch: Dispatch<ReduxActions.Action<any>>) => {

    var data = {
      properties: properties
    } as ValidateDgdPropertyRequest;

    dispatch(validateDgdPropertyRequested(data));

    asyncRequest({
      url: validateSaveUrl,
      method: 'POST',
      data: data,
      callback: function(response: jsendResponse) {
        if (response.status == 'success') {
          dispatch(validateDgdPropertyConfirmed({
            ...validateDgdProperty,
            ...response.data,
          }))

          const autoCheckProperties = ['aircraftType', 'shipmentType', 'origin', 'destination'] as (keyof DgdData)[];
          const requiresAutoCheckUpdate = properties.map(w => w.field).filter(w => autoCheckProperties.indexOf(w as any) > -1);
          if (requiresAutoCheckUpdate) {
            runAutoCheck(autoCheckStatus)(dispatch);
          }
        } else {
          dispatch(validateDgdPropertyError(data));
          if (response.status == 'error' && response.message) {
            alert(response.message);
          }
          else if (response.status != 'unauthorised') {
            alert('There was a problem saving the property, please try again or refresh the page.');
          }
        }
      }
    });
  }
}
