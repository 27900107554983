import * as React from 'react';
import JavascriptTimeAgo from 'javascript-time-ago'
import ReactTimeAgo from 'react-time-ago'
import en from 'javascript-time-ago/locale/en'
import * as dayjs from 'dayjs';
JavascriptTimeAgo.locale(en)

export function FormattedRelative(props: { value: Date }) {

  let { value: date } = props;

  if (!date) {
    return null;
  }

  if (typeof(date) === 'string') {
    date = dayjs(date).toDate();
  }

  return <ReactTimeAgo date={date}/>
}
