import { handleActions } from 'redux-actions';
import * as Actions from '../constants/actions';

const initialState: DgdResources = null;

export default handleActions<DgdResources, any>({
  [Actions.ADD_RESOURCE_SUCCESS]: (state, action) => {
    var payload = action.payload as RuleTypeVisibility;

    return {
      ...state,
      // operatorReviewFilters: {
      //   ...state.operatorReviewFilters,
      //   ...payload
      // }
    } as DgdResources;
  },

  [Actions.LOAD_RESOURCE_SECTIONS]: (state, action) => {
    var payload = action.payload as RuleTypeVisibility;

    return {
      ...state,
      ...payload
    } as DgdResources
  }
}, initialState);
