import * as React from 'react';
import * as CheckActions from 'checkspa/actions/check';
import { connect } from 'react-redux';
import * as classNames from 'classnames';
import { RootState } from 'checkspa/reducers';
import { ReferenceShowArea, DgrReference } from 'checkspa/ui/components';
import { ReferenceArea } from 'checkspa/ui/containers/ReferenceArea';
import { ResourcesArea } from 'checkspa/ui/containers/ResourcesArea';
import { acceptanceCheckComplete } from 'checkspa/selectors/acceptance-check';

import { Left, Right, Columns, Expand, Times } from 'library/icons';
import { bindActionCreators } from 'redux';
import { canCheckBeValidatedInCurrentStateByUser, isOwner } from 'checkspa/selectors/acceptance-check';
import { uiSettings } from 'environment/settings';
const { iconSize } = uiSettings;

export class RightSidebarComponent extends React.Component<RightSidebar.Props, RightSidebar.State> {
  rightSidebarToggle(activeTab, overrideShowSidebar?: boolean) {
    let showSidebar = true;
    let activeTabToShow = activeTab;
    const { rootState } = this.props;
    const rightSidebarActiveTab = rootState.rightSidebar.activeTab;
    const dockSidebar = rootState.rightSidebar.dockSidebar
    const { actions } = this.props

    if (!dockSidebar && (overrideShowSidebar === true || overrideShowSidebar === false)) {
      showSidebar = overrideShowSidebar;
    }

    if ((!dockSidebar && ( activeTabToShow === rightSidebarActiveTab )) || (( window.innerWidth < 1629 && activeTabToShow === rightSidebarActiveTab ))) {
      showSidebar = false
      activeTabToShow = null
    }

    actions.changeActiveTab( activeTabToShow );
    actions.showRightSidebar( showSidebar );

    this.setState({
      ...this.state,
      showLeftSidebar: false
    })
  }

  closeRightSidebar() {
    const { actions } = this.props

    actions.showRightSidebar( false );
  }

  dockSidebar() {
    const { actions, rootState } = this.props

    actions.dockSidebar( rootState.rightSidebar.dockSidebar ? false : true )
  }

  sidebarContainerWidth(value: number) {
    var { actions } = this.props;

    actions.changeSidebarWidth( value );
  }

  switchTabsListView() {
    var { actions } = this.props,
        { userSettingData } = this.props.rootState

    if (userSettingData.checklistView === 'tabs') {
      actions.switchTabsListView('list');
    } else {
      actions.switchTabsListView('tabs');
    }
  }

  renderShowArea(showAreaId: string) {
    const { rootState, actions } = this.props;

    var validationDetails = rootState.questionToValidationLinks.find(w => w.previewArea === showAreaId);

    if (!validationDetails)
      return null;

    return (
      <ReferenceShowArea key="preview"
          questionToValidation={ validationDetails }
          dgd={ rootState.dgdData }
          currentUser={ rootState.applicationState.user }
          dgdSourceType={ rootState.dgdData.dgdSourceType }
          onClose={ () => { actions.previewAreaHide(); } } />
    );
  }

  render() {
    const { rootState, actions, renderReferenceArea, renderResourcesArea, allowDocking, dockingPossible } = this.props;
    const { dgdData, rightSidebar } = rootState;
    const dockedSidebar = rightSidebar.dockSidebar
    let rightSidebarActiveTab = rightSidebar.activeTab;

    const rightSidebarClass = classNames({
      ["dgd-viewer-right-sidebar--expand"]: rightSidebar.show
    }, 'dgd-viewer-right-sidebar',
       'content-sidebar-wrapper__item-2')

    var previewArea = null;

    if (rightSidebar.previewArea != null) {
      previewArea = rightSidebar.previewArea;
    }

    if (rightSidebarActiveTab === 'packagePreview' && !renderReferenceArea) {
      rightSidebarActiveTab = 'dgd';
    }

    const showDgdPreview = dgdData.dgdImages && dgdData.dgdImages.length > 0;

    function isActiveAndVisible(tab: 'dgd' | 'dgr' | 'resources' | 'packagePreview' | 'preview') {
      return rightSidebarActiveTab === tab && rightSidebar.show;
    }

    return (
      <div className={rightSidebarClass}>
        <div className='content-sidebar-wrapper__item-2__wrapper'>
          <div className='tabs-list-toggles'>
            { allowDocking &&
              <>
                { dockedSidebar && dockingPossible ?
                  <button type="button" onClick={(event) => this.dockSidebar() } title="Undock sidebar" className="btn btn-link btn--marginaround-icon right-sidebar__undock-button"><Expand height={iconSize} width={iconSize} /></button>
                  :
                  <>
                    {
                      dockingPossible ?
                      <button type="button" onClick={(event) => this.dockSidebar() } title="Dock sidebar" className="btn btn-link btn--marginaround-icon right-sidebar__dock-button"><Columns height={iconSize} width={iconSize} /></button>
                      :
                      <button type="button" onClick={(event) => this.dockSidebar() } title="This feature can only be used on larger screens" className="btn btn-link btn--marginaround-icon right-sidebar__dock-button-inactive" disabled><Columns height={iconSize} width={iconSize} /></button>
                    }
                  </>
                }
              </>
            }

            { rightSidebar.dockSidebarWidth === 700 ?
              <button type="button" onClick={(event) => this.sidebarContainerWidth(1000) } title="Larger sidebar" className="btn btn-link btn--marginaround-icon">
                <Left height={iconSize} width={iconSize} />
              </button>
            :
              <button type="button" onClick={(event) => this.sidebarContainerWidth(700) }title="Smaller sidebar" className="btn btn-link btn--marginaround-icon">
                <Right height={iconSize} width={iconSize} />
              </button>
            }

            { !dockedSidebar &&
              <button type="button" onClick={(event) => this.closeRightSidebar()} title="Close" className="btn btn-link btn--marginaround-icon">
                <Times height={iconSize} width={iconSize} />
              </button>
            }
          </div>

          <ul className="right-sidebar-tabs nav nav-tabs" role="tablist">
            { showDgdPreview &&
              <li className={ isActiveAndVisible('dgd') ? 'active' : ''}>
                <button className={ isActiveAndVisible('dgd') ? 'right-sidebar-tabs__item right-sidebar-tabs__item--active' : 'right-sidebar-tabs__item'} onClick={(event) => this.rightSidebarToggle('dgd')} type="button">DGD</button>
              </li>
            }

            { rootState.dgrReferenceStatus.loading || rootState.dgrReferenceStatus.title || rootState.dgrReferenceStatus.body
              ? <li className={ isActiveAndVisible('dgr') ? 'active' : ''}>
                  <button className={ isActiveAndVisible('dgr') ? 'right-sidebar-tabs__item right-sidebar-tabs__item--active' : 'right-sidebar-tabs__item'} onClick={(event) => this.rightSidebarToggle('dgr')} type="button">DGR</button>
                </li>
              : '' }

            { previewArea &&
              <li className={ isActiveAndVisible('preview') ? 'active' : ''}>
                <button className={ isActiveAndVisible('preview') ? 'right-sidebar-tabs__item right-sidebar-tabs__item--active' : 'right-sidebar-tabs__item'}
                  onClick={(event) => this.rightSidebarToggle('preview')} type="button">
                  Preview
                </button>
              </li>
            }

            {renderReferenceArea &&
              <li className={ isActiveAndVisible('packagePreview') ? 'active' : ''}>
                <button className={ isActiveAndVisible('packagePreview') ? 'right-sidebar-tabs__item right-sidebar-tabs__item--active' : 'right-sidebar-tabs__item'} onClick={(event) => this.rightSidebarToggle('packagePreview')} type="button">Package Preview</button>
              </li>
            }

            { renderResourcesArea !== false &&
              <li className={ isActiveAndVisible('resources') ? 'active' : ''}>
                <button className={ isActiveAndVisible('resources') ? 'right-sidebar-tabs__item right-sidebar-tabs__item--active' : 'right-sidebar-tabs__item'} onClick={(event) => this.rightSidebarToggle('resources')} type="button">Resources</button>
              </li>
            }

            <li className="">
              <a className="right-sidebar-tabs_item" href="mailto:dgautocheck@iata.org">Support</a>
            </li>

          </ul>

          <div className='tab-pane-content'>
            { rightSidebarActiveTab === 'dgd' && showDgdPreview ? <ReferenceArea /> : null }

            { rightSidebarActiveTab === 'dgr' ? <DgrReference dgrStatus={ rootState.dgrReferenceStatus } actions={actions}  /> : null }

            { rightSidebarActiveTab === 'preview' &&
              this.renderShowArea(rootState.rightSidebar.previewArea)
            }

            { rightSidebarActiveTab === 'packagePreview' ? renderReferenceArea : null }

            { rightSidebarActiveTab === 'resources' ?
              <ResourcesArea
                apiUrls={this.props.rootState.apiUrls}
                dgdResources={this.props.rootState.dgdResources}
                actions={actions}
                saveResourceUrl={ rootState.userSettingData.dgdResourceEditing }
                dgdResourceLoadingStatus={ rootState.userSettingData.dgdResourceLoadingStatus }
                isOwner={ isOwner(rootState) }
                acceptanceCheckComplete={ acceptanceCheckComplete(rootState) }
              /> : null }
          </div>
        </div>
      </div>
    )
  }
}

declare namespace RightSidebar {
  export interface Props {
    rootState?: RootState; // TODO: Does this really need the WHOLE rootState? Feels like it was used out of convenience
    actions?: typeof CheckActions;
    renderReferenceArea?: any;
    renderResourcesArea?: boolean; // True by default
    allowDocking?: boolean; // True by default
    canValidate?: boolean;
    dockingPossible: boolean;
  }

  export interface State {
    /* empty */
  }
}

function mapStateToProps(state: RootState, ownProps: RightSidebar.Props) {
  var allowDocking = true;
  if (typeof ownProps.allowDocking !== 'undefined') {
    allowDocking = ownProps.allowDocking;
  }

  return {
    ...ownProps,
    rootState: state,
    allowDocking: allowDocking,
    canValidate: canCheckBeValidatedInCurrentStateByUser(state),
  } as RightSidebar.Props;
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(CheckActions as any, dispatch)
  };
}

export const RightSidebar = connect(mapStateToProps, mapDispatchToProps)(RightSidebarComponent);
