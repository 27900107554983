import * as React from 'react';
import * as CheckActions from 'checkspa/actions/check';
import { Times, Right, Left } from 'library/icons';

declare namespace ViewerBlock {
  export interface Props {
    actions: typeof CheckActions;
    blocks: Array<DgdPreviewBlock>;
    blockIndex: number;
    rect: DgdPreviewRect;
    showBlockControls?: boolean;
    ratio: number;
  }
  export interface State {
  }
}

export class ViewerBlock extends React.Component<ViewerBlock.Props, ViewerBlock.State> {
  render() {
    const { rect, blocks, blockIndex, actions, ratio } = this.props

    const prevBlock = blocks[blockIndex - 1];
    const nextBlock = blocks[blockIndex + 1];

    return (
      <div className='dgd-viewer-block'
        style={{
          bottom: rect.bottom * ratio,
          height: rect.height * ratio,
          left: rect.left * ratio,
          right: rect.right * ratio,
          top: rect.top * ratio,
          width: rect.width * ratio
        }}>

        { this.props.showBlockControls &&
          <div className='block-controls'>
            <button type="button" title="Close/reset" className='btn btn-link btn-close' onClick={(event) => actions.dgdViewerCancel(null) }>
              <Times height={30} width={30} />
            </button>

            <ul className='pagination'>
              { prevBlock !== undefined &&
                <li>
                  <button className='pagination__link' onClick={() => this.moveToBlock( prevBlock ) }>
                    <Left height={20} width={20}/>
                  </button>
                </li>
              }

              <li>{ blockIndex + 1 } of { this.props.blocks.length }</li>

              { nextBlock !== undefined &&
                <li>
                  <button className='pagination__link' onClick={() => this.moveToBlock( nextBlock ) }>
                    <Right height={20} width={20}/>
                  </button>
                </li>
              }
            </ul>
          </div>
        }
      </div>
    )
  }

  moveToBlock = (block: DgdPreviewBlock) => {
    const { actions } = this.props;

    actions.dgdViewerFocusOnId({ id: block.id, pageNumber: block.pageNumber});
  }
}
