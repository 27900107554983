import { createAction } from 'redux-actions';
import { Actions } from '../../constants';
import { Dispatch } from 'redux';
import { asyncRequest } from 'communication/async-request';

const documentationCompletedRequest = createAction(Actions.DOCUMENTATION_COMPLETED_REQUEST);
const documentationCompletedConfirmed = createAction<AcceptanceCheckState>(Actions.DOCUMENTATION_COMPLETED_CONFIRMED);
const documentationCompletedError = createAction(Actions.DOCUMENTATION_COMPLETED_ERROR);

export function documentationComplete(documentationCompleteUrl: string): (dispatch: Dispatch<ReduxActions.Action<any>>) => void {

  return (dispatch: Dispatch<ReduxActions.Action<any>>) => {

    dispatch(documentationCompletedRequest());

    asyncRequest({
      url: documentationCompleteUrl,
      method: 'POST',
      callback: function(response: jsendResponse) {
        if (response.status != 'success') {
          dispatch(documentationCompletedError());
        } else {
          dispatch(documentationCompletedConfirmed(response.data))
        }
      }
    });
  }
}
